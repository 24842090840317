import React from 'react'
import styled, { css } from 'styled-components'

import { config } from '../config'

const Wrapper = styled.div`
  max-width: 100%;

  > ul {
    list-style-type: '–';
    padding-left: 10px;

    > li {
      padding-left: 8px;
    }
  }

  @media (min-width: ${config.breakpoints.tablet}) {
    columns: 2;
    column-gap: 50px;

    ${(props) =>
      props.preventBreakInside &&
      css`
        > * {
          -webkit-column-break-inside: avoid;
          page-break-inside: avoid;
          break-inside: avoid-column;
        }
      `}
  }
`

export function Text({ children, preventBreakInside }) {
  return <Wrapper preventBreakInside={preventBreakInside}>{children}</Wrapper>
}
